module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-128696676-1","G-NHFT7GJY59"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.mohragency.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lewis Mohr Insurance Agency","short_name":"Lewis Mohr","start_url":"/","background_color":"#ffffff","theme_color":"#303F9F","display":"minimal-ui","icon":"src/assets/icon.png"},
    }]
